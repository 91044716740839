import request from "@/utils/request";

export function homeLinsts(data) {
	return request({
		url: "/homeLinsts",
		method: "get",
		params: data,
	});
}
export function relatedRecommen(data) {
	return request({
		url: "/relatedRecommen",
		method: "post",
		params: data,
	});
}
// 各页面banner接口,参数type,
// 1:首页顶部banner
// 2:专栏页顶部banner
// 3:活动首页顶部banner
// 4:专题顶部banner
// 5:香港专区顶部banner
// 6:美国专区顶部banner
export function bannerInfo(data) {
	return request({
		url: "/getRecommendBanner",
		method: "post",
		data: data,
	});
}
export function homeTopList(data) {
	return request({
		url: "/homeTopList",
		method: "get",
		params: data,
	});
}
export function homeHotList(data) {
	return request({
		url: "/homeHotList",
		method: "get",
		params: data,
	});
}
export function hotTopicsList(data) {
	return request({
		url: "/dexList",
		method: "get",
		params: data,
	});
}
export function dexRecommendList(data) {
	return request({
		url: "/dexRecommendList",
		method: "get",
		params: data,
	});
}
export function homeSearchList(data) {
	return request({
		url: "/homeSearchList",
		method: "get",
		params: data,
	});
}

export function getArticleList(data) {
	return request({
		url: "/getArticleList",
		method: "post",
		data: data,
	});
}

//   今日大事件

export function getEventDetails(data) {
	return request({
		url: "/getEventDetails",
		method: "post",
		data: data,
	});
}
//   分类列表

export function categoryLists(data) {
	return request({
		url: "/categoryLists",
		method: "get",
		params: data,
	});
}
//   主编推荐
export function getRecommendLists(data) {
	return request({
		url: "/getRecommendLists",
		method: "post",
		data: data,
	});
}
//   产业标签详情

export function getLabelDetails(data) {
	return request({
		url: "/getLabelDetails",
		method: "get",
		params: data,
	});
}
//   专题
export function getSpecial(data) {
	return request({
		url: "/getSpecial",
		method: "post",
		data: data,
	});
}
//   他人主页
export function toUserInfo(data) {
	return request({
		url: "/toUserInfo",
		method: "post",
		data: data,
	});
}
//   他人发布的文章
export function getUserInformation(data) {
	return request({
		url: "/getUserInformation",
		method: "post",
		data: data,
	});
}
//   活动列表
export function getActivityList(data) {
	return request({
		url: "/getActivityList",
		method: "post",
		data: data,
	});
}
//   活动详情
export function getActivityDetails(data) {
	return request({
		url: "/getActivityDetails",
		method: "post",
		data: data,
	});
}
//   文章详情

export function getInformationDetails(data) {
	return request({
		url: "/getInformationDetails",
		method: "post",
		data: data,
	});
}
//   新闻早班车
export function getNewsDetails(data) {
	return request({
		url: "/getNewsDetails",
		method: "post",
		data: data,
	});
}
//   每周要闻
export function getWeekDetails(data) {
	return request({
		url: "/getWeekDetails",
		method: "post",
		data: data,
	});
}
//   合作相关

export function getCooperationDetails(data) {
	return request({
		url: "/getCooperationDetails",
		method: "post",
		data: data,
	});
}
//   获取app配置信息
export function getAppConfig(data) {
	return request({
		url: "/getAppConfig",
		method: "post",
		data: data,
	});
}
//   栏目列表
export function getPopularColumn(data) {
	return request({
		url: "/getPopularColumn",
		method: "post",
		data: data,
	});
}
//   周作者热榜
export function getWeekAuthorList(data) {
	return request({
		url: "/getWeekAuthorList",
		method: "post",
		data: data,
	});
}
//   作者列表
export function getAuthorList(data) {
	return request({
		url: "/getAuthorList",
		method: "post",
		data: data,
	});
}
//   热门搜索
export function hotSearch(data) {
	return request({
		url: "/hotSearch",
		method: "post",
		data: data,
	});
}
//文章标签详情
export function getTicketDetails(data) {
	return request({
		url: "/getTicketDetails",
		method: "get",
		params: data,
	});
}
//   搜索
export function search(data) {
	return request({
		url: "/search",
		method: "post",
		data: data,
	});
}
//   搜素记录

export function getSearchRecords(data) {
	return request({
		url: "/getSearchRecords",
		method: "post",
		data: data,
	});
}
//   删除搜索历史
export function delSearchRecords(data) {
	return request({
		url: "/delSearchRecords",
		method: "post",
		data: data,
	});
}
//   搜索更多
export function searchMore(data) {
	return request({
		url: "/searchMore",
		method: "post",
		data: data,
	});
}
//   获取区号
export function getPhoneCode(data) {
	return request({
		url: "/getPhoneCode",
		method: "post",
		data: data,
	});
}
//   获取验证码
export function sendSms(data) {
	return request({
		url: "/sendSms",
		method: "post",
		data: data,
	});
}
//   获取验证码
export function sendEmailSms(data) {
	return request({
		url: "/sendMailSms",
		method: "post",
		data: data,
	});
}
//   重置密码
export function forgotPassword(data) {
	return request({
		url: "/forgotPassword",
		method: "post",
		data: data,
	});
}
//   专题详情
export function getSpecialDetails(data) {
	return request({
		url: "/getSpecialDetails",
		method: "post",
		data: data,
	});
}
//   收藏资讯
export function collectionInformation(data) {
	return request({
		url: "/collectionInformation",
		method: "post",
		data: data,
	});
}
//   取消收藏资讯
export function cancelCollectionInformation(data) {
	return request({
		url: "/cancelCollectionInformation",
		method: "post",
		data: data,
	});
}
//   获取通知列表
export function getNoticeList(data) {
	return request({
		url: "/getNoticeList",
		method: "post",
		data: data,
	});
}
//   获取通知详情
export function getNoticeDetail(data) {
	return request({
		url: "/getNoticeDetails",
		method: "post",
		data: data,
	});
}
//   获取专栏热榜
export function getZLHostLists(data) {
	return request({
		url: "/getZLHostLists",
		method: "get",
		data: data,
	});
}

//   提交pvuv
export function pvuv(data) {
	return request({
		url: "/pvuv",
		method: "post",
		data: data,
	});
}

//   获取工具导航数据
export function toolsList(data) {
	return request({
		url: "/toolsList",
		method: "post",
		data: data,
	});
}

//   获取活动列表
export function acitvityLists(data) {
	return request({
		url: "/admin/activity/lists",
		method: "post",
		data: data,
	});
}
//   获取活动首页
export function acitvityHome(data) {
	return request({
		url: "/admin/activity/home",
		method: "post",
		data: data,
	});
}

//   获取活动列表
export function acitvityMyLists(data) {
	return request({
		url: "/admin/activity/mylists",
		method: "post",
		data: data,
	});
}

//   获取活动详情
export function acitvityDetail(data) {
	return request({
		url: "/admin/activity/details",
		method: "post",
		data: data,
	});
}

export function acitvityDetailHeji(data) {
	return request({
		url: "/admin/activity/mainLists2",
		method: "post",
		data: data,
	});
}

//   编辑活动
export function acitvityEdit(data) {
	return request({
		url: "/admin/activity/update",
		method: "post",
		data: data,
	});
}

//   删除活动
export function acitvityDel(data) {
	return request({
		url: "/admin/activity/destroy",
		method: "post",
		data: data,
	});
}

//  购买活动票务
export function acitvityOrder(data) {
	return request({
		url: "/admin/activity/order",
		method: "post",
		data: data,
	});
}
//  购买活动票务
export function orderCheck(data) {
	return request({
		url: "/admin/activity/orderCheck",
		method: "post",
		data: data,
	});
}
//  购买活动票务
export function orderCancel(data) {
	return request({
		url: "/admin/activity/orderCancel",
		method: "post",
		data: data,
	});
}

//   编辑订单
export function orderUpdate(obj) {
	return request({
		url: "/admin/activity/orderUpdate",
		method: "post",
		data: obj,
	});
}

export function activitylists(obj) {
	return request({
		url: "/admin/activity/lists",
		method: "post",
		data: obj,
	});
}

// BTC 环形图页面数据
export function getBtcList(data) {
	return request({
		url: "/dataereList",
		method: "post",
		params: data,
	});
}

export function etfData(data) {
	return request({
		url: "/etfData",
		method: "post",
		data: data,
	});
}

export function spotData(data) {
	return request({
		url: "/spotData",
		method: "post",
		data: data,
	});
}
